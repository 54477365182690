// Notification
.notification-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noti-item {
    cursor: pointer;
    border-radius: 6px;
}

.readedItem {
    background-color: $gray;
}

.unReadItem {
    background-color: $light-orange;
}

.bell-noti-icon {
    color: $white;
    border-radius: 50%;
    padding: 6px;
    width: 16px;
    height: 16px;
    bottom: 4px;
    position: relative;
}

.noti-badge {
    margin-right: 0.2rem;
    span {
        color: $white;
        background-color: $red;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        border-radius: 14px;
        padding: 4px 0px;
        white-space: nowrap;
    }
}

.noti-content {
    margin-top: 12px;
}

.fileDownload {
    cursor: pointer;
    color: $blue;
    text-decoration: underline;
    margin-left: 10px;
}

.preview-img {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #B1B7C1;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;

    .background-img {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
}

// pc
@media screen and (min-width: 900px) {

    // .notification-index {
    //     min-width: 800px;
    // }
    .noti-date {
        text-align: end;
        min-width: 150px;
    }

    .noti-item {
        padding: 24px 20px;
        margin-bottom: 12px;
        width: 55%;
    }

    .preview-img {
        height: 315px;
    }

    .noti-banner {
        width: 100%;
        max-width: 757px;
        height: auto;
    }

    .noti-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
    }

    .noti-title {
        display: flex;
        justify-content: space-between;
    }

    // .noti-detail-page {
    //     min-width: 1100px;
    // }

    .card-body {
        display: flex;
        justify-content: center;
    }

    .noti-detail-container {
        width: 70%;

        .noti-banner-detail {
            width: 100%;
        }
    }

    .noti-content {
        margin-bottom: 24px;
    }

    .noti-footer {
        padding-top: 24px;
        border-top: 1px dashed $orange;
    }
}

// mobile
@media screen and (max-width: 899px) {
    // .notification-index {
    //     min-width: 300px;
    // }

    .noti-item {
        padding: 16px;
        margin-bottom: 16px;
        width: 100%;
    }

    .preview-img {
        height: 113px;
    }

    .noti-banner {
        width: 100%;
    }

    .noti-header {
        display: flex;
        flex-direction: column-reverse;

    }

    .noti-date {
        min-width: 150px;
        text-align: left;
        margin-bottom: 8px;
    }

    .noti-title {
        display: flex;
        justify-content: space-between;

        h6 {
            position: relative;
            top: 2px;
        }
    }

    // .noti-detail-page {
    //     min-width: 327px;
    // }

    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .noti-detail-container {
        width: 100%;

        .noti-banner-detail {
            width: 100%;
        }
    }

    .noti-content {
        margin-bottom: 24px;
    }

    .noti-footer {
        padding-top: 24px;
        border-top: 1px dashed $orange;
    }
}