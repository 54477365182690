.table-fixed-column {
    table-layout: fixed;
}

.table-center  {
  text-align: center;
}

.column-middle {
  vertical-align: middle;
}

.graph-header {
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.chart-label {
  margin-top: 22px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: bold;
}

.annotation-flex {
  display: flex;
  align-items: baseline;
  margin-top: 11px;
}

.annotation-last-year {
  width: 43px;
  height: 12px;
  background-color: #467395;
}

.label-last-year {
  margin-left: 7px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
}

.annotation-this-year {
  width: 43px;
  height: 12px;
  background-color: #FF7F45;
  margin-left: 8px;
}

.label-this-year {
  margin-left: 7px;
  font-size: 14px;
  font-weight: bold;
}

.chart-unit {
  width: 35px;
  font-size: 17px;
  position: relative;
  margin-left: 9px;
  align-self: flex-start;
  color: #F8601B;
}

.top-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .nav {
        width: 100%;
    }

    .billing-table {
        th {
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: $black;
        }

        td {
            color: $black;
        }
    }
}

.billing-table {
  th {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: $black;
  }

  td {
    color: $black;
  }

  .mark-icon {
    left: 2px;
  }
}

.chart-common {
    width: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
    cursor: pointer;

    .chart-wrapper {
        width: 100%;
    }

}

.chart-header {
  overflow-y: scroll;
}

.chart-body {
  overflow-y: scroll;

  .chart-wrapper {
    width: 700px;
  }
}

.mark-icon {
    position: relative;
    width: 9px;
    bottom: 1px;
}

.statement-card-body {
    justify-content: flex-start;
}

.bill-detail-item {
    .message {
        text-align: center;
        display: block;
        margin-top: 64px;
    }
}

@media screen and (min-width: 900px) {

    .chart-common {

        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: 0.25px;
            color: $black;

        }
    }

    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .bill-area {
        width: 50%;
        min-width: 600px;

        .bill-content {
            width: 80%;
            margin-bottom: 3em;

            .billing-amount-title {
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.25px;
                display: flex;
                align-items: baseline;
            }

            .yen-icon {
                padding: 8px 12px;
                border-radius: 50%;
                font-size: 18px;
                background-color: $yellow;
                position: relative;
                top: 3px;
            }

            .amount {
                font-weight: 700;
                font-size: 32px;
                line-height: 44px;
                color: $orange;
                letter-spacing: 0.25px;
                margin-left: 15px;
                word-break: break-all;
            }

            .tax-included {
                font-weight: 700;
                font-size: 32px;
                line-height: 44px;
                letter-spacing: 0.25px;
                color: #6C757D;

            }
        }

        .show-bill {
            padding: 2em 2em;
            border: 4px solid #FFAC7D;
            border-radius: 4px;
            background: $white;
            // min-width: 594px;
        }

        .group-button {
            .invoice-blue {
                background-color: #21699E;
                color: #ffffff;
            }
            .invoice-gray-light {
                background-color: #d6dce5;
                color: #ffffff; 
            }
        }
    }

    .noti-area {
        width: 50%;
        min-width: 600px;
        margin-top: 32px;

        .card-header {
            flex-direction: row;
        }

        .noti-img {
            width: 302px;
            height: 201px;
        }
    }

    .top-sub-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        color: $black;
    }

    .square {
        position: relative;
        font-size: 10px;
        line-height: 10px;
        bottom: 2px;
        margin-right: 5px;
    }

    .top-screen {

        .navigate-link {
            color: $orange;
            cursor: pointer;

            .navigate-text {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.28px;
                text-decoration: underline;
                margin-left: 5px;
            }
        }

        .info-detail {
            width: 100%;

            .list-bill-info {
                .list-bill-info-item {
                    border-bottom: 1px solid #BCBFC4;
                    padding: 20px 0;

                    .title {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: 0.25px;
                    }
                }

                .list-bill-info-item:last-child {
                    border-bottom: none;
                }
            }

            .bill-info-detail-table {
                margin-bottom: 20px;

                .square {
                    position: relative;
                    font-size: 14px;
                    line-height: 14px;
                    bottom: 2px;
                    margin-right: 5px;
                }

                table {
                    margin-bottom: 0px;
                }
            }


        }

        .usage-detail {
            min-width: 100%;

            .usage-detail-item {
                padding: 24px;
                background: #FFF7F2;
                border: 1px solid #FFAC7D;
                border-radius: 4px;
                margin-top: 20px;
            }

            .item-title {
                margin-bottom: 20px;
                display: flex;

                .noti-img {
                    width: 18.78px;
                    height: 24px;
                    margin-right: 5px;
                }

                .square-icon {
                    width: 12px;
                    position: relative;
                    height: 12px;
                    top: 5px;
                }

                h4 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.25px;
                    color: $black;
                }

            }



            table {
                background-color: $white;
                margin: 20px 0 0 0;
                table-layout: fixed;
            }
        }
    }
}

@media screen and (max-width: 899px) {
  .chart-label {
    margin-top: 16px;
    font-size: 18px;
    font-weight: bold;
  }
  .label-last-year {
    margin-left: 7px;
    margin-right: 8px;
    font-size: 12px;
    font-weight: bold;
  }

  .label-this-year {
    margin-left: 7px;
    font-size: 12px;
    font-weight: bold;
  }
    .square {
        position: relative;
        font-size: 9px;
        line-height: 9px;
        bottom: 3px;
        margin-right: 5px;
    }

    .chart-common {
        width: 700px;

        h2 {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.25px;
            color: $black;

        }
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: auto;
    }

    .bill-detail-contain {
        padding: 0;
        width: 100%;
    }

    .bill-area {
        width: 100%;


        .bill-content {
            width: 100%;
            margin-bottom: 50px;

            .billing-amount-title {
                font-size: 18px;
                line-height: 26px;
            }

            .yen-icon {
                padding: 6px 10px;
                border-radius: 50%;
                font-size: 18px;
                background-color: $yellow;
                position: relative;
                top: 3px;
            }

            .amount {
                font-weight: 700;
                font-size: 24px;
                line-height: 34px;
                color: $orange;
                letter-spacing: 0.25px;
                margin-left: 8px;
            }

            .tax-included {
                font-weight: 700;
                font-size: 24px;
                line-height: 34px;
                letter-spacing: 0.25px;
                color: #6C757D;
            }
        }

        .show-bill {
            padding: 20px 16px;
            border: 4px solid #FFAC7D;
            border-radius: 4px;
            background: $white;
        }

        .group-button {
            .invoice-blue {
                background-color: #21699E;
                color: #ffffff;
            }
            .invoice-gray-light {
                background-color: #d6dce5;
                color: #ffffff; 
            }
        }
    }

    .noti-area {
        width: 100%;
        margin-top: 24px;

        .card-header {
            flex-direction: row;
        }

        .noti-img {
            width: 230px;
            height: 153px;
        }
    }

    .top-sub-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: $black;
    }

    .usage-detail {
        width: 100%;

        table {
            width: 100%;
            table-layout: fixed;
            overflow-wrap: break-word;

            th {
                vertical-align: middle;
            }
        }
    }

    .top-screen {
        .contract {
            justify-content: space-between;
        }

        .navigate-link {
            color: $orange;
            cursor: pointer;

            .navigate-text {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.07px;
                text-decoration: underline;
                margin-left: 5px;
            }
        }

        .info-detail {
            width: 100%;

            .list-bill-info {
                .list-bill-info-item {
                    border-bottom: 1px solid #BCBFC4;
                    padding: 16px 0;

                    .title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.25px;
                    }
                }

                .list-bill-info-item:last-child {
                    border-bottom: none;
                }
            }

            .bill-info-detail-table {
                margin-bottom: 20px;

                .square {
                    position: relative;
                    font-size: 10px;
                    line-height: 10px;
                    bottom: 3px;
                    margin-right: 5px;
                }

                table {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }


        }

        .usage-detail {
            width: 100%;

            .billing-table {
                th {
                    font-size: 10px;
                    line-height: 18px;
                    padding: 4px;
                }

                td {
                    padding: 2px;
                }
            }

            .usage-detail-item {
                padding: 12px;
                background: #FFF7F2;
                border: 1px solid #FFAC7D;
                border-radius: 4px;
                margin-top: 20px;
            }

            .item-title {
                margin-bottom: 20px;
                display: flex;

                .noti-img {
                    width: 18.78px;
                    height: 24px;
                    margin-right: 5px;
                }

                .square-icon {
                    width: 12px;
                    position: relative;
                    height: 12px;
                    top: 5px;
                    ;
                }

                h4 {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.25px;
                    color: $black;
                }

            }

            table {
                background-color: $white;
                margin: 20px 0 0 0;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .contract {
        display: flex;
        justify-content: flex-end;
    }

    .bill-detail-item {
        width: 600px;
    }

    .contract-number {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.25px;
        color: $black;
    }

    .contract-name {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.25px;
        color: #6C757D;
        align-self: center;
    }

    .bill-detail-contain {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .change-month {
        display: flex;

        .month-year {
            margin: 0 10px;
            background-color: #F5F5F5;
            border-radius: 4px;
            display: inline-flex;

            span {
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.25px;
                color: $black;
                padding: 4px 6px;
                border-radius: 4px;
            }
        }

        .change-month-icon {
            display: inline;
            border-radius: 4px;
            padding: 4px 6px;
            background-color: $orange;
            color: $white;
            cursor: pointer;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 899px) {
    .select-custom {
        // width: 209px !important;
    }

    .bill-detail-item {
        width: 100%;
    }

    .contract {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .contract-number {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $black;
    }

    .contract-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: #6c757d;
        align-self: center;
    }


    .change-month {
        display: flex;

        .month-year {
            margin: 0 10px;
            background-color: #F5F5F5;
            border-radius: 4px;
            display: inline-flex;

            span {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.25px;
                color: $black;
                padding: 4px 6px;
                border-radius: 4px;
            }
        }

        .change-month-icon {
            display: inline;
            border-radius: 4px;
            padding: 4px 6px;
            background-color: $orange;
            color: $white;
            cursor: pointer;
            font-size: 14px;
            line-height: 22px;
            font-weight: 700;
        }
    }
}

.select-custom {
    //height: 36px;
    // width: 306px;
    gap: 10px;
    background-color: #fff7f2;
    border: 1px solid $color-border;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $black;
    cursor: pointer;
    position: relative;
    .select-item {
        cursor: pointer;
    }
    ul {
        transform: unset !important;
        margin-top: 55px !important;
    }
}

.detail-meter-screen {
    .meter-detail {
        border-top: 1px solid #BCBFC4;
    }

    @media screen and (min-width: 900px) {
        .card-header {
            display: flex;
            justify-content: space-between;
        }

        .meter-detail-contain {
            width: 50%;
            padding-top: 45px;
        }

        .top-sub-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 35px;
            color: $black;
            margin-bottom: 25px;
        }

        .meter-detail {
            padding: 20px 0;
        }

        .meter-detail-item-title,
        .id-number {
            padding: 10px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.25px;
            color: $black;
        }

        .meter-detail-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.25px;
            color: $black;
            padding: 10px 0;
        }

        .square {
            position: relative;
            font-size: 10px;
            line-height: 10px;
            bottom: 3px;
            margin-right: 5px;
        }
    }

    @media screen and (max-width: 899px) {
        .card-header {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: auto;
        }

        .square-icon {
            width: 12px;
            position: relative;
            height: 12px;
            top: 5px;
        }

        .meter-detail-contain {
            width: 100%;
        }

        .top-sub-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: -0.28px;
            color: $black;
            margin-bottom: 19px;
        }

        .meter-detail {
            padding: 15px 0;
        }

        .meter-detail-item-title,
        .id-number {
            padding: 8px 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: $black;
        }

        .meter-detail-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: $black;
            padding: 10px 0;
        }
    }
}

.tab-content {
    width: 100%;
}

.chart-screen {
    width: 65%;

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        color: $white;
        background-color: $orange;
        border: 2px solid $orange;
    }

    .nav-link {
        border: 2px solid #BCBFC4;
    }

    .usage-graph {
        padding-bottom: 40px;
    }

    .electric-chart {
        background-color: #DEEDD8;
    }

    .gas-chart {
        background-color: $light-orange;
    }
}

.chart-display-tab {
    .nav {
        background-color: $light-orange;
    }
}

@media screen and (max-width: 1279px) and (min-width: 900px) {
    .chart-container {
        .noti-img {
            width: 20px;
            height: 26px;
            margin-right: 5px;
        }

        .noti-large-img {
            width: 46px;
            height: 46px;
            bottom: 6px;
        }
    }

    .detail-amount {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .compare-item {
            display: flex;
            justify-content: flex-start;
            width: 100% !important;
            padding: 15px 24px;
            align-items: center;
        }

        .compare-item:first-child {
            margin-bottom: 8px;
        }

        .compare-item:last-child {
            margin-top: 8px;
        }

        .usage-amount {
            margin-left: 19px;
        }
    }

    .chart-screen {
        min-width: 600px !important;
    }
}

@media screen and (min-width: 900px) {
    .no-bill-record {
        font-weight: 500;
        display: flex;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.25px;
        color: #6C757D;
    }

    .tab-content {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .detail-billed-tab {
        width: 100%;
    }

    .chart-display-tab {
        display: flex !important;
        flex-direction: column;
        align-items: center;

        .nav {
            padding: 8px !important;
        }
    }

    .chart-container {
        .noti-img {
            width: 25px;
            height: 33px;
            margin-right: 10px;
        }

        .noti-large-img {
            width: 80px;
            height: 80px;
            position: relative;
            bottom: 6px;
        }

        .message {
            color: #6C757D;
        }
    }

    .chart-screen {
        min-width: 920px;

        .tab-pane {
            width: 100%;
            background-color: $light-orange;
        }

        .nav {
            li:first-child {
                margin-right: 8px;
            }
        }
    }

    .detail-amount {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .compare-item {
            background-color: $white;
            display: flex;
            justify-content: flex-start;
            width: 49%;
            padding: 32px;
            align-items: center;
            border-radius: 4px;
        }

        // .compare-item:first-child {
        //     margin-right: 16px;
        // }

        // .compare-item:last-child {
        //     margin-left: 16px;
        // }

        .note-text {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.25px;
            color: #6C757D;
            margin-top: 6px;
        }

        .usage-amount {
            // white-space: nowrap;
            margin-left: 24px;
        }

        .amount-text {
            word-break: break-all;
            font-weight: 700;
            font-size: 40px;
            line-height: 44px;
            letter-spacing: 0.25px;
            color: $black;

            .text-status {
                white-space: nowrap;
            }

            .up-arrow,
            .increase-text {
                color: $red;
                margin-right: 16px;
            }

            .down-arrow,
            .decrease-text {
                color: $green;
                margin-right: 16px;
            }
        }

    }

    .electric-chart,
    .gas-chart {
        padding: 32px;
    }

    .title-contain {

        img,
        .top-sub-title {
            margin-bottom: 24px;
        }
    }
}

@media screen and (max-width: 899px) {
  .usage-graph {
    overflow-x: scroll;
    overscroll-behavior-inline: none;
  }
    .chart-screen {
        width: 100%;
        background-color: $light-orange;

        .tab-pane {
            width: 100%;
        }
    }

    .no-bill-record {
        font-weight: 500;
        display: flex;
        justify-content: center;
        text-align: center;
        letter-spacing: 0.25px;
        color: #6C757D;
    }

    .chart-container {
        .noti-img {
            width: 20px;
            height: 26px;
            margin-right: 5px;
        }

        .noti-large-img {
            width: 46px;
            height: 46px;
            bottom: 6px;
        }

        .message {
            color: #6C757D;
        }
    }


    .chart-screen {
        .nav {
            li:first-child {
                margin-right: 4px;
            }

            li {
                width: 45%;
            }
        }
    }

    .detail-amount {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .compare-item {
            background-color: $white;
            display: flex;
            justify-content: flex-start;
            width: 100%;
            padding: 15px 24px;
            align-items: center;
        }

        .compare-item:first-child {
            margin-bottom: 8px;
        }

        .compare-item:last-child {
            margin-top: 8px;
        }

        .note-text {
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.25px;
            color: #6C757D;
            margin-top: 4px;
        }

        .usage-amount {
            margin-left: 19px;
        }

        .amount-text {
            word-break: break-all;
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: 0.25px;
            color: $black;

            .text-status {
                white-space: nowrap;
            }

            .up-arrow,
            .increase-text {
                color: $red;
                margin-right: 10px;
            }

            .down-arrow,
            .decrease-text {
                color: $green;
                margin-right: 10px;
            }
        }

        .up-arrow,
        .increase-text {
            color: $red;
            margin-right: 8px;
        }


        .down-arrow,
        .decrease-text {
            color: $green;
            margin-right: 8px;
        }
    }

    .electric-chart,
    .gas-chart {
        padding: 12px;
    }

    .title-contain {

        img,
        .top-sub-title {
            margin-bottom: 20px;
        }
    }
}
