.required {
  color: $red;
}

.card {
  --cui-card-border-width: none;
}

.success {
  border: 1px dashed #358b43;
  color: #358b43;
  font-weight: 500;
  padding: 12px;
  border-left: none;
  border-right: none;
}

.text-ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.formInput {
  .form-check-input {
    margin-top: 5px;
    border: 2px solid $border-checkbox;
    -webkit-appearance: none !important;
    cursor: pointer;
  }

  .form-check-input:checked {
    background-color: #f8601b;
    border-color: #f8601b;
  }
}

.text-brown {
  color: #6C757D;
}

.word-break-all {
  word-break: break-all;
}

// destop
@media screen and (min-width: 900px) {
  .width-date-table {
    width: 10%;
  }

  .width-name-table {
    width: 30%;
  }

  .width-auth {
    width: 600px !important;
  }

  .success {
    font-size: 16px;
    line-height: 24px;
  }

  .formSelect {
    margin: 10px 0;

    &-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $black;
      margin: 10px 0;
    }

    &-custom {
      cursor: pointer;
      height: 56px;
      padding: 10px 18px;
      gap: 10px;
      background-color: #fff7f2;
      border: 1px solid #ffe0ce;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: $black;
      max-width: 100%;
    }
  }

  .iframe-tag {
    height: calc(100vh - 388px);
    width: 100%;
  }

  .text-18 {
    font-size: 18px;
    line-height: 28px;
  }

  .text-16 {
    font-size: 16px;
    line-height: 24px;
  }

  .text-14 {
    font-size: 14px;
    line-height: 20px;
  }

  .break-spaces {
    white-space: break-spaces !important;
  }

  .card-header {
    padding: 30px 24px;
    height: 82px;
    display: flex;
    justify-content: start;
  }

  .card-body {
    padding: 1em;
  }

  .page-title {
    width: fit-content;
    color: $orange;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.25px;
  }

  .page-sub-title {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 8px;
    letter-spacing: 0.25px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: $black;
  }
  .sub-title {
    display: contents;
    &-terms {
      font-size: 14px;
      color: $black;
      width: 100%;
    }
  }
  .electric-title {
    margin-bottom: 12px;
  }

  .nav {
    padding-left: 24px !important;

    .nav-link {
      width: 180px;
    }
  }
}

input.disabled {
  pointer-events: none;
  color: #333333;
  background: #E4E4E4;
}

// mobile
@media screen and (max-width: 899px) {
  .width-date-table {
    width: 13%;
  }

  .width-name-table {
    width: 20%;
  }

  .success {
    font-size: 14px;
    line-height: 22px;
    width: 100%;
  }

  .formSelect {
    &-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: $black;
      margin: 8px 0;
    }

    &-custom {
      cursor: pointer;
      height: 44px;
      padding: 8px 16px;
      gap: 10px;
      background-color: #fff7f2;
      border: 1px solid #ffe0ce;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $black;
      max-width: 100%;
    }

    option {
      width: 100%;
      max-width: 100%;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .formInput {
    &-label {
      font-size: 12px !important;
    }

    .form-check-input {
      margin-top: 6px;
    }
  }

  .iframe-tag {
    width: 100%;
    height: 478px;
  }

  .text-18 {
    font-size: 16px;
    line-height: 24px;
  }

  .text-16 {
    font-size: 14px;
    line-height: 20px;
  }

  .text-14 {
    font-size: 12px;
    line-height: 16px;
  }

  .card-header {
    padding: 20px 12px;
    height: 64px;
    display: flex;
    justify-content: center;
  }

  .card-body {
    padding: 20px 12px;
    width: 100%;
  }

  .page-title {
    color: $orange;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.25px;
  }

  .page-sub-title {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 0px;
    letter-spacing: -0.28px;
    color: $black;
    margin-top: 14px;
  }

  .sub-title {
    line-height: 15px;
    display: block;
    &-terms {
      font-size: 12px;
      color: $black;
      width: 100%;
    }
  }

  .nav {
    .nav-item {
      width: 50%;

      .nav-link {
        width: 100%;
      }
    }
  }
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.text-orange {
  color: $orange;
}

.text-blue {
  color: $blue;
}

.text-black {
  color: $black !important;
}

.text-green {
  color: $green;
}

.text-yellow {
  color: $yellow;
}

.card {
  width: 100%;
  filter: drop-shadow(0px 4px 18px #fbd7b6);
  border-top: 4px solid $orange;
  min-width: 90%;

  &.form-qa-not-login {
    width: 90%;
  }
}

.card-header {
  box-sizing: border-box;
  gap: 10px;
  background: $white;
  border-bottom: 1px dashed $orange;
  border-radius: 4px 4px 0px 0px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  align-items: center;
}

.card-body {
  background-color: $white;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 485px);
}

@media screen and (max-width: 899px) {
  .card-body {
    min-height: calc(100vh - 460px);
  }

  .card {
    &.form-qa-not-login {
      width: 95%;
    }
  }
  .chartjs-tooltip {
    transform: translate(-70%, 0);
    display: none !important;
  }
  .chartjs-tooltip-body-item {
    font-size: 0.775rem;
  }
}

.card-outline-orange {
  background-color: #fff7f2;
  border: 2px solid #ffac7d;
  border-radius: 5px;
  padding: 18px 34px;
}

.card-outline-light-orange {
  background-color: #fff7f2;
  border: 4px solid #FFAC7D;
  border-radius: 5px;
  display: inline-block;
  padding: 18px 24px;
}

.card-outline-blue {
  background-color: #ebedfb;
  border: 2px solid #b2b8e7;
  border-radius: 5px;
  display: inline-block;
}

.card-outline-green {
  background-color: #f5fff1;
  border: 2px solid #4eae5d;
  border-radius: 5px;
  display: inline-block;
}

.pagination {
  .page-item {
    &.disabled {
      .page-link {
        color: #dbd3d3;
      }
    }
  }

  .page-link.active,
  .active>.page-link {
    color: $white;
    background-color: $orange;
    border-color: $orange;
  }

  .page-link {
    height: 28px;
    width: auto;
    min-width: 28px;
    color: $orange;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-password {
    &::placeholder {
      font-size: 4px;
    }
  }
}

.nav {
  background-color: $gray;
  position: relative;
  top: 1px;

  .nav-item {
    cursor: pointer;

    .nav-link {
      display: flex;
      justify-content: center;
      color: #6c757d;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;

      &.active {
        color: $orange;
      }
    }
  }
}

.border-radius-4 {
  border-radius: 4px;
}

.bg-gray {
  background-color: $gray !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-dark-gray {
  background-color: $dark-gray !important;
}

a {
  text-decoration: none;
}

.formInput {
  margin: 10px 0;

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $black;
    margin: 10px 0;
  }

  &-custom {
    padding: 14px 24px;
    gap: 10px;
    height: 56px;
    background: #fff7f2;
    border: 1px solid #ffe7e6;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: $black;
    outline: none;
    -webkit-appearance: none !important;
    width: 100%;
  }

  .link {
    margin-left: 5px;
    cursor: pointer;
  }

  .text-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.07px;
    text-decoration: underline;
    margin-left: 5px;
    color: $orange;
  }

  // &-custom:read-only {
  //   background: #e8e8e8;
  //   border: unset;
  // }

  &-error {
    border: 1px solid #ff0f00;
  }
}

.formTextarea {
  margin: 10px 0;

  &-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $black;
    margin: 10px 0;
  }

  &-input {
    padding: 10px 18px;
    gap: 10px;
    background-color: #fff7f2;
    border: 1px solid #ffe0ce;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: $black;
  }
}

.text-error {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.25px;
  color: #ff0f00;
  align-self: flex-start;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.input-password {
  padding-right: 48px;

  &::placeholder {
    font-size: 8px;
  }
}

input {
  text-overflow: ellipsis;
}

.pr-48 {
  padding-right: 48px;
}

[data-simplebar] {
  width: 100%;
  height: 100%;
}
