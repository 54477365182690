// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

// If you want to add custom CSS you can put it here.
@import "button";
@import "burgerMenu";
@import "common";
@import "dropdown";
@import "modal";
@import "mypage";
@import "auth";
@import "pageNotification";
@import "pageEnergySaving";
@import "guidePages";
@import "top";
@import "errorPages";
@import "stepper";
