.main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.guide-pages-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 19px 100px;
    min-width: 100%;
}

.row-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#usage-guide-text-note {
    border-bottom: 1px solid $orange;
    padding: 0px 20px 8px 20px;
}

.contact-contain {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .contact-item:first-child {
        padding-top: 16px;
        border-top: 1px solid #BCBFC4;
    }

    .contact-item:last-child {
        padding-bottom: 16px;
        border-bottom: 1px solid #BCBFC4;
    }
}

.card-outline-explain {
    background: $light-orange;
    border: 4px solid #FFAC7D;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    width: 100%;
    padding: 1.5em 0.5em;
    letter-spacing: 0.25px;
}

.text-qa-confirm {
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: $black;
    min-height: 2.5em;
    white-space: pre-line;
    word-break: break-all;
}

@media screen and (min-width: 900px) {
    .confirm-qa-modal-group-item {
        display: flex;
        justify-content: space-between;
    }

    .confirm-qa-modal-item {
        width: 50%;
    }

    .text-qa-confirm {
        font-size: 16px;
    }

    .main {
        width: 70%;
    }

    .contact-item {
        padding: 8px;
        display: flex;
        justify-content: space-between;
        width: 60%;
    }

    .guide-pages-item {
        width: 100%;
        margin-bottom: 35px;
    }

    .item-container {
        padding: 16px;
        flex: 0 0 auto;
        width: 50%;
        display: flex;
        cursor: pointer;
    }


    .img-icon {
        width: 18px;
        position: relative;
        left: 40px;
        display: flex;
        align-items: center;
        justify-content: center
    }

    h5 {
        padding: 0 10px;
        letter-spacing: 0.25px;
        line-height: 24px;
    }
}

@media screen and (max-width: 899px) {
    .card-outline-explain {
        padding: 0.5em 0.1em;
    }

    .click-here {
        display: flex;
        justify-content: center;
    }

    .text-qa-confirm {
        font-size: 14px;
    }

    .main {
        width: 100%;
    }

    .contact-item {
        padding: 6px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .guide-pages-item {
        width: 100%;
        margin-bottom: 32px;
    }

    .row-items {
        flex-direction: column;
    }

    .item-container {
        flex: 0 0 auto;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: center;
        padding: 0;
        margin-bottom: 12px;
        cursor: pointer;
    }


    .img-icon {
        width: 14px;
        position: relative;
        left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h5 {
        position: relative;
        top: 2px;
    }

    .title-center {
        width: 100%;
        text-align: center;
    }

    .row-qa {
        padding: 0 16px;
    }
}
