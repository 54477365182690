.energy-saving-index {
    width: 100%;

    .list-content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .page-title {
            margin: 0;
        }
    }

    @media screen and (min-width: 900px) {

        .list-item {
            width: 25%;
            flex: 0 0 25%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 1.4em;
            height: 21em;
            padding: 0.4rem;
        }

        .list-item-img-contain {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 11rem;
            overflow: hidden;
        }

        .list-item-img {
            width: 100%;
        }

        .date-time {
            margin-top: 0.5em;
            margin-bottom: 0.1em;
        }
    }

    @media screen and (max-width: 899px) {
        .list-item-img-contain {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 180px;
            overflow: hidden;
        }

        // .list-item-img {
        //     width: 327px;
        // }

        .list-item {
            margin-bottom: 30px;
            width: 50%;
            flex: 0 0 50%;

            a {
                display: flex;
                justify-content: start;
            }
        }

        .date-time {
            display: inline-block;
            margin-top: 10px;
        }
    }

    @media screen and (max-width: 575px) {
        .list-item {
            width: 100%;
            flex: 0 0 100%;
        }
    }
}

.energy-saving-detail {
    .sun-icon {
        width: 30px;
    }

    .card-outline-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $light-orange;
        border: 3px solid #FFAC7D;
        box-shadow: 4px 6px 0px #d8bfb7;
        border-radius: 4px;
    }

    .card-outline-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $light-orange;
        border: 4px solid #FFAC7D;
        border-radius: 4px;
    }

    .text-explain-contain {
        display: flex;
        align-items: center;
    }

    .summer-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #E2F2FF;
        margin-bottom: 20px;

        h5 {
            color: #0D6FB8;
            margin: 0px 12px;
            font-weight: 700;
        }
    }

    .winter-header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $light-orange;
        margin-bottom: 20px;

        h5 {
            color: $orange;
            margin: 0px 12px;
            font-weight: 700;
        }
    }

    .content-title {
        background: $light-orange;
        border: 4px solid #FFAC7D;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .page-title-number {
            color: $white;
            background-color: $orange;
            margin: 0 0.5rem;
            border-radius: 50%;
            padding: 1px 10px;
        }
    }

    .snowflake-icon {
        width: 30px;
        height: 30px;
        background: radial-gradient(50% 50% at 50% 50%, #0D6FB8 27.36%, rgba(13, 111, 184, 0) 100%);
        color: $white;
        padding: 4px;
    }

    .sun-icon {
        width: 30px;
        height: 30px;
        background: radial-gradient(50% 50% at 50% 50%, #FCCA0D 0%, rgba(252, 202, 13, 0) 100%);
        color: $white;
        padding: 4px;
    }

    .explain-notice {
        font-weight: 600;
        background: linear-gradient(transparent 60%, #FFEDE1 0%);
    }

    .supText {
        font-size: 75%;
        position: relative;
        bottom: 0.4em;
    }

    .summer-txt {
        color: #1F8DE2;
        font-weight: 600;
    }

    .winter-txt {
        color: $orange;
        font-weight: #FB6C0D;
    }

    a {
        text-decoration: underline;
        color: $orange;
        font-weight: 700;
    }

    .explain-img {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-content {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23149BFFFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 4px;
    }

    .content-lead {
        background-color: $light-orange;
        border-width: 4px 0px;
        border-style: solid;
        border-color: #FFDCC6;
        margin: auto;
    }

    .top-content {
        margin-bottom: 2rem;
    }

    .text-explain {
        margin-bottom: 3rem;
    }

    .content-title {
        h3 {
            padding: 20px 30px;
        }
    }

    .content-sub-title {
        border-bottom: 2px solid #FFAC7D;
        margin-bottom: 20px;
    }

    @media screen and (min-width: 900px) {
        min-width: 1200px;

        .img-box {
            display: flex;
            justify-content: space-between;
        }

        .content-lead {
            width: 80%;


            h4 {
                padding: 32px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
            }
        }

        .top-content {
            padding: 0px 168px;
        }

        .main-content {
            padding: 82px 168px;
        }

        .text-explain {
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .card-outline-top {
            padding: 32px;
            margin-bottom: 52px;
        }

        .card-outline-bottom {
            padding: 24px 53px;
            margin-bottom: 18px;
        }

        .summer-header,
        .winter-header {
            padding: 16px;

            .h5 {
                font-size: 18px;
            }
        }


        .quote-txt {
            font-size: 12px;
        }

        .content-title {
            margin: 43px 0;
        }

        .content-sub-title {
            h4 {
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 7px;
            }
        }
    }

    @media screen and (max-width: 899px) {
        .text-explain {
            font-family: 'Noto Sans JP';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
        }

        .card-outline-top,
        .card-outline-bottom {
            padding: 16px 12px;
            margin-bottom: 24px;
        }

        .summer-header,
        .winter-header {
            padding: 13px;

            .h5 {
                font-size: 16px;
            }
        }


        .quote-txt {
            font-size: 10px;
        }

        .content-lead {
            h4 {
                padding: 13px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .top-content {
            padding: 16px 12px;
        }

        .main-content {
            padding: 16px 12px;
        }

        .content-title {
            h3 {
                padding: 12px 24px;
            }
        }

        .content-title {
            margin: 24px 0;
        }

        .content-sub-title {
            display: flex;
            justify-content: center;
            align-items: center;

            h4 {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                margin-bottom: 7px;
            }
        }
    }
}
