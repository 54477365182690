.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--cui-modal-margin) * 2);
  -webkit-appearance: none;
}

.modal-content {
  -webkit-appearance: none;
}

.modal-header {
  background-color: $white;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: $orange;
  border-top: 4px solid $orange;
  border-bottom: 1px dashed $orange;
  border-radius: 4px 4px 0px 0px;

  .modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.25px;
  }
}

.modal-body {
  display: flex;
  background-color: #FFFFFF;
  justify-content: center;
  border-radius: 4px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.modal-footer {
  display: flex;
  justify-content: center;
  border-top: none;
}

.modal-img {
  width: 271px;
}

.modal-sm {
  --cui-modal-width: 598px !important;
}

.modal-noti {
  padding: 40px 20px 30px;

  &-text-content {
    color: $black;
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 700;
  }

  &-icon-close {
    margin: 20px 20px 0 0;
    align-self: end;
    cursor: pointer;
  }

  &-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $black;
  }

  &-btn {
    button {
      margin-top: 30px;
      background: $orange;
      border-radius: 5px;
      padding: 10px;
      min-width: 182px;
      border: unset;
      font-weight: 700;
    }
  }

  &-btn-ok {
    background: $orange;
  }

  &-btn-cancel {
    background: $white !important;
    border: 1px solid $dark-gray !important;
    color: $dark-gray !important;

    &:hover {
      background: $blue !important;
    }
  }
}

@media screen and (max-width: 899px) {
  .modal-header {
    .modal-title {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
