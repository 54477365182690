.burger-menu-container {
    position: absolute;
    right: 0px;
    top: 60px;
    width: 100%;
    height: 100vh;
    background-color: rgba(28, 25, 25, 0.5);
}

.burger-icon {
    width: 25px;
    height: 20px;
    color: #F8601B;
}

.burger-list {
    list-style: none;
    background-color: #FFFFFF;

    li {
        position: relative;
        right: 20px;
    }

    li:first-child {
        border-top: none;
    }

}

.burger-list-parent {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #F8601B;
    border-top: 1px solid #BCBFC4;
}

.burger-button{
    padding-bottom: 24px;
}

.burger-list-child {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.25px;
    color: #F8601B;
    padding-top: 18px;
}

.burger-sublist {
    list-style: disc;
}

@media screen and (min-width: 900px) {
    .burger-icon {
        display: none;
    }

    .burger-menu-container {
        display: none;
    }
}