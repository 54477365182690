.btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    border-radius: 4px;
    font-family: 'Noto Sans JP';
    font-style: normal;
    text-align: center;
    letter-spacing: 0.25px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: unset;
    cursor: pointer;
    padding: 14px 24px;
}


.btn-orange {
    background: $orange;
    color: $white;

    &:disabled {
        background-color: $orange !important;
        opacity: 0.5;
    }

    &:hover {
        background-color: $orange;
    }
}

.btn-yellow {
    background: $yellow;
    color: $black;

    &:disabled {
        background-color: #fffaea !important;
        opacity: 0.5;
    }

    &:hover {
        background-color: $yellow;
    }
}


.btn-light-orange {
    background-color: $light-orange;
    color: $orange;

    &:hover {
        background-color: $orange;
    }
}

// destop
@media screen and (min-width: 900px) {
    .btn {
        padding: 14px 24px;
        font-size: 16px;
    }
}


// mobile
@media screen and (max-width: 899px) {
    .btn {
        padding: 9px 12px;
        font-size: 12px;
    }
}
