.loading-container {
  z-index: 9999;
  .modal-content {
    background-color: unset !important;
    box-shadow: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset;
  }
}

.loading-container ~ .modal-backdrop {
  z-index: 9998;
}