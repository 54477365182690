.dropdown-scroll {
  display: flex;
  height: 200px;
}

.dropdown {
  width: 100%;
  min-width: 170px;

  &-toggle {
    display: flex !important;
    background: $transparent !important;
    font-weight: 700 !important;

    &::after {
      margin: 9px -10px auto auto !important;
      border-top: 0.7em solid !important;
      border-right: 0.5em solid transparent !important;
      border-left: 0.5em solid transparent !important;
    }
  }

  &-input {
    width: 100%;
    border: none;
    background-color: $gray;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    border-bottom: 1px solid $border-color;
    &:focus-visible {
      outline: none;
    }
  }

  &-label-no-data {
    height: 30px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: $black;
  }

  &-toggle-contract {
    display: flex !important;
    background: $transparent !important;
    font-weight: 700 !important;

    &::after {
      margin: 2px -14px auto auto !important;
      border-top: 0.6em solid !important;
      border-right: 0.4em solid transparent !important;
      border-left: 0.4em solid transparent !important;
      color: $black !important
    }
  }

  &-menu {
    width: 100% !important;
    position: absolute !important;
    inset: 0 auto auto 0 !important;
    overflow-y: auto;

    &.not-padding-top{
      padding-top: unset;
    }
  }

  &-item {
    font-size: 14px;
    color: $black;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: break-spaces;
    &:hover {
      background-color: $light-orange !important;
    }
  }
}

@media screen and (max-width: 899px) {
  .dropdown {
    &-toggle {
      &::after {
        margin: 8px -2px auto auto !important;
      }
    }
    &-toggle-contract {
      &::after {
        margin: 2px -4px auto auto !important;
        border-top: 0.5em solid !important;
      }
    }
    &-item {
      font-size: 12px;
    }
    &-input {
      font-size: 12px;
    }
    &-label-no-data {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: 900px) {
  .dropdown {
    &-toggle-contract {
      &-item-selecting {
        font-size: 14px;
        font-weight: 500;
      }
    }

  }
}
