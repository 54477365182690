body {
  overflow: visible;
  font-family: "Noto Sans JP";
  color: $black;
}

.wrapper {
  width: 100%;
  // @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  // @include transition(padding .15s);
}

.app-content {
  overflow: auto;
  background-color: $light-orange;
}

.header {
  .header-text-list {
    font-weight: 700;
    color: $orange;
    text-decoration: none;
  }

  .header-text-list:last-child {
    border-right: none;
  }
}

.sidebar {
  background: #FFFFFF;
  width: 209px;

  .nav-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6C757D;

    &.active {
      background: #FFF7F2;
      color: $orange;

      .nav-icon {
        color: $orange;
      }
    }

    &:hover {
      background: #FFF7F2;
      color: $orange;

      .nav-icon {
        color: $orange;
      }
    }
  }

  .nav-icon {
    height: 20px;
    width: 20px;
    color: #6C757D;
  }
}

.footer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: #FFFFFF;
  border-top: 1px solid $orange;

}

.footer-text-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #1A1311;
  margin-top: 9px;

  p {
    margin: 0;
  }
}

.layout-auth {
  background: #FFF7F2;

  &-content {
    margin-top: 68px;
    min-height: calc(100vh - 197px);
    padding: 50px 0;
  }
}

.header-custom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: $white;
}

@media screen and (min-width: 900px) {
  .sidebar-mitsubachi {
    position: fixed;
    min-height: 100vh !important;
    width: 209px;
    top: 68px !important;
    z-index: 99 !important;
  }

  .sidebar {
    .nav-item {
      width: 186px;
      left: 12px;
      position: relative;

      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Noto Sans JP";
        font-weight: 700;
        font-size: 8px;
        line-height: 12px;
        letter-spacing: -0.28px;
        color: #FFFFFF;
        background-color: #FF594F;
        width: 16px;
        height: 16px;
        position: relative;
        bottom: 7px;
        right: 123px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
      }
    }
  }

  .app-content {
    margin-left: 209px;
    padding: 1.5em;
    min-height: calc(100vh - 197px);
    font-size: 14px;
    margin-top: 68px;
  }

  .header {
    height: 68px;
    --cui-header-min-height: 68px;
    padding: 14px 24px;
    border-bottom: 1px solid #EDEDED;

    .container-fluid {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .header-logo {
      width: 88.2px;
      height: 18px;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .header-text-list {
      border-right: 2px solid $orange;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
      padding: 0px 16px;
    }

  }

  .footer-logo-view {
    width: 278px;
    height: 50px;
    border-right: 1px solid #FFAC7D;
    display: flex;
    align-items: center;
  }

  .footer-logo {
    width: 258px;
    height: 31px;
  }

  .footer-detail {
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .footer-text-description {
    margin-top: 0 !important;
    margin-left: 20px !important;
  }

  .footer {
    height: 129px !important;
    z-index: 100;
    padding: 25px 0;
    justify-content: center;
    width: 100%;

    .footer-list-screen {
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      ul {
        list-style: disc;
        color: $orange;

        .footer-text-item {
          color: $orange;
          margin-bottom: 6px;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          text-decoration: none;
        }
      }
    }
  }

  .footer-text-list {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: $orange;
    margin: 7px 0;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: #2819af;
    }
  }

  .modal-dialog {
    &.modal-dialog-centered {
      &.modal-xl {
        --cui-modal-width: 95% !important;
        justify-content: center;
      }
    }
  }
  .modal-content {
    max-width: 1080px;
  }
  .footer-modal-iframe {
    .iframe {
      width: 100%;
      height: 500px;
      display: block;
    }
    .icon-close {
      width: 25px;
      height: 25px;
      position: absolute;
      top: -50px;
      right: 0;
      cursor: pointer;
    }
  }

  .layout-auth {
    &-content {
      min-height: calc(100vh - 197px);
    }
  }

  .btn-auth {
    height: 41px !important;
  }
}

@media screen and (max-width: 899px) {
  .layout-auth {
    &-content {
      margin-top: 60px;
    }
  }

  .sidebar-mitsubachi {
    position: sticky;
    min-height: auto !important;
    width: 100%;
    top: 68px !important;
    z-index: 99 !important;
  }

  .hidden-on-mobile {
    display: none !important;
  }

  .header {
    height: 60px;
    --cui-header-min-height: 60px;
    padding: 13px 26px;

    .container-fluid {
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .header-logo {
      width: 87.52px;
      height: 18px;
    }

    .btn-auth {
      height: 36px !important;
    }


    .header-text-list {
      display: block;
      padding: 24px 0;
      border-right: 2px solid $orange;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  .app-content {
    margin-left: 0px;
    margin-top: 56px;
    padding: 24px 12px;
    font-size: 12px;
    overflow-x: hidden;
    margin-top: 60px;
  }

  .sidebar {
    width: 100%;
    height: 56px;
    z-index: 1 !important;
    top: 60px !important;

    .nav-link {
      .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Noto Sans JP";
        font-weight: 700;
        font-size: 8px;
        line-height: 12px;
        letter-spacing: -0.28px;
        color: #FFFFFF;
        background-color: #FF594F;
        width: 16px;
        height: 16px;
        position: relative;
        bottom: 40px;
        left: 4px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
        text-decoration: none !important;
      }

      &.active {
        background-color: #FFFFFF;

        .sideBar-name {
          text-decoration: underline;
          text-underline-offset: 10px;
        }
      }

      &:hover {
        background-color: #FFFFFF;

        .sideBar-name {
          text-decoration: underline;
          text-underline-offset: 10px;
        }
      }
    }
  }

  .sidebar a {
    float: left;
  }

  .sidebar-nav .nav-link {
    display: grid;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #6C757D;
    width: 25%;
    justify-content: center;
    justify-items: center;
  }

  .sidebar-nav {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  html:not([dir=rtl]) .sidebar-nav .nav-icon:first-child {
    margin-left: 0;
  }

  .footer {
    height: auto;
    display: block;
    width: 100%;

    .footer-logo {
      width: 199.74px;
      height: 24px
    }

    .footer-list-screen {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      padding-bottom: 5px;

      ul {
        list-style: disc;
        color: $orange;


        .footer-text-item {
          color: $orange;
          margin-bottom: 6px;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          text-decoration: none;
        }
      }
    }

    .footer-list-container {
      width: 100%;
    }
  }

  .footer-text-list {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $orange;
    margin-bottom: 14px;
    text-decoration: none;
  }

  .modal-dialog {
    &.modal-dialog-centered {
      &.modal-xl {
        --cui-modal-width: 95% !important;
        justify-content: center;
      }
    }
  }
  .modal-content {
    max-width: 1080px;
  }
  .footer-modal-iframe {
    .iframe {
      width: 100%;
      height: 400px;
      display: block;
    }
    .icon-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -50px;
      right: 0;
      cursor: pointer;
    }
  }

  .layout-auth {
    &-content {
      min-height: calc(100vh - 237px);
      padding: 12px 4px;
    }
  }

}

@media (max-width: 899px) {
  html:not([dir=rtl]) .sidebar:not(.sidebar-end):not(.show) {
    margin-left: 0 !important;
  }
}

@media (min-width: 900px) {
  html:not([dir=rtl]) .sidebar.hide:not(.sidebar-end) {
    margin-left: 0;
  }
}
