$orange: #F8601B;
$light-orange: #FFF7F2;
$yellow: #FCCA0D;
$white: #FFFFFF;
$black: #463529;
$blue: #21699E;
$green: #4EAE5D;
$gray: #F5F5F5;
$dark-gray: #BCBCBD;
$red: #FF0F00;
$transparent: #00000000;
$dark: #818181;
$text-black: #464646;
$color-border: #ffe7e6;
$border-checkbox: #B6B6B6;
$switch: #BCBFC4;
