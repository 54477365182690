.err-container {
    border-top: 4px solid #F8601B;
    box-shadow: 0px 4px 18px #FBD7B6;
    border-radius: 4px;
    background-color: $white;

    .message-container {
        margin-bottom: 1.5em;
        border-top: 1px dashed #6C757D;
        border-bottom: 1px dashed #6C757D;
        background: #F5F5F5;
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 500;
        color: #6C757D;

    }
}

@media screen and (min-width: 900px) {
    .err-container {
        padding: 2em;

        img {
            width: 462px;
            margin-bottom: 1.5em;
        }

        .message-container {
            padding: 12px 100px;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.25px;

            .exclamation-icon {
                font-size: 20px;
                margin-right: 7px;
            }
        }
    }
}

@media screen and (max-width: 899px) {
    .err-container {
        img {
            width: 315px;
            margin-bottom: 1em;
        }


        .message-container {
            padding: 12px 34px;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;

            .exclamation-icon {
                font-size: 16px;
                margin-right: 7px;
            }
        }
    }
}