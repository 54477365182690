.confirmContract {
  position: relative;
  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.25px;
    color: $black;
  }

  &-contract-end {
    color: $red;
  }

  &-left {
    width: 640px;

    &-main {
      margin-top: 36px;
      padding: 32px 0;
      gap: 10px;
      width: 100%;
      background: #ffffff;
      border: 4px solid #ffac7d;
      border-radius: 4px;
    }

    hr {
      width: 100%;
    }
  }

  &-text {
    color: $black;

    &-left {
      font-weight: 400;
      letter-spacing: 0.25px;
    }

    &-right {
      font-weight: 500;
      letter-spacing: -0.28px;
    }
  }

  &-right {
    width: 640px;

    &-main {
      width: 100%;
      padding: 16px 0;
      gap: 10px;
      position: relative;
      .confirmContract-contract-end {
        position: absolute;
        top: 4px;
        width: 100%;
      }
    }

    &-top {
      hr {
        width: 100%;
      }
    }

    &-bottom {
      hr {
        width: 100%;
      }
    }
  }
}

.myPage-container {
  min-height: calc(100vh - 239px);
  background: $white;
  filter: drop-shadow(0px 4px 18px #fbd7b6);

  &-header {
    border-top: 4px solid $orange;
    border-bottom: 1px dashed $orange;
    border-radius: 4px 4px 0 0;
    height: 82px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $orange;
    padding: 0 24px;

    .contract-name {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.25px;
      color: #6c757d;
    }

    .contract-number {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
      color: $black;
      margin-left: 4px;
    }
  }

  &-main {
    gap: 32px;
    padding: 50px 0;

    .title-list {
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      color: $black;
    }

    .myPage-list {
      gap: 32px;
      width: 100%;

      &-item {
        width: 40%;
        height: 162px;
        background: #fff7f2;
        border: 4px solid #ffe6d6;
        border-radius: 4px;
        gap: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $black;
        cursor: pointer;
        text-align: center;
      }
    }

    .list-collapse {
      &-item {
        margin: 15px 0;

        .collapse-inner {
          max-width: 100%;
          width: 775px;
        }

        .collapse-wrapper {
          margin-left: 32px;
          margin-right: 32px;
        }

        .title-collapse {
          font-weight: 700;
          font-size: 24px;
          line-height: 35px;
          color: $black;
          padding: 9px 0px 9px 12px;
          gap: 12px;
          height: 53px;
          border-bottom: 4px solid #ffac7d;
          cursor: pointer;
        }

        .content-collapse {
          margin-top: 32px;
          margin-bottom: 24px;
          width: 775px;
          background: $white;
          border: 4px solid #ffac7d;
          border-radius: 4px;

          &-top {
            padding: 20px;
            gap: 24px;
            border-bottom: 1px solid #ffac7d;

            .office-item {
              border-bottom: 1px dashed #FFAC7D;
              padding: 0.5rem 0;
            }
            .office-item:last-child {
              border-bottom: none;
            }

            .title {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.25px;
              color: $orange;
            }

            .text-icon {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.25px;
              color: $black;
              gap: 6px;
            }

            .text-normal {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.25px;
              color: $black;
              margin-left: 24px;
            }

            .title-right {
              a {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.25px;
                text-decoration-line: underline;
                color: $orange;
                margin-left: 24px;
              }
            }

            .title-item-list {
              border-bottom: '1px dashed #FFAC7D';
              &:last-child {
                border: none;
              }
            }
          }

          &-bottom {
            padding: 32px;
            gap: 24px;
            height: 50%;

            .text-note {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              letter-spacing: 0.25px;
              color: $black;
            }

            .link-url {
              width: 100%;
              background: #fff7f2;
              border: 1px solid #ffac7d;
              border-radius: 4px;
              padding: 12px;
              gap: 10px;
              position: relative;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              letter-spacing: 0.25px;
              color: #f8601b;

              .icon {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }

        .collapse-empty {
          margin-top: 32px;
        }

        .data-collapse {
          margin-top: 32px;
          margin-bottom: 24px;
          // gap: 10px;
          width: 775px;

          &-item {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.25px;
            color: $black;
            border-bottom: 1px solid #ecedef;
            border-left: 1px solid #ecedef;
            border-right: 1px solid #ecedef;
          }

          &-item:last-child {
            border-bottom: unset;
          }

          &-item:first-child {
            border-top: 1px solid #ecedef;
          }

          &-note {
            background: #fff7f2;
            border-left: 4px solid #f8601b;
            padding: 12px;
            gap: 10px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            letter-spacing: 0.25px;

            .icon {
              margin-right: 4px;
              vertical-align: middle;
            }

            a {
              text-decoration-line: underline;
              color: #f8601b;
            }
          }

          table {
            th {
              padding: 10px 16px;
            }
            tbody td {
              padding: 10px 16px;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        .table-collapse {
          margin-top: 40px;
          display: flex;
          width: 100%;
          justify-content: center;
          gap: 20px;
          flex-wrap: wrap;

          &-header {
            background: #f5f5f5;
            padding: 8px;
            gap: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: $black;
            border-bottom: 1px solid #bcbfc4;
          }

          &-main {
            padding: 6px 8px;
            gap: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: $black;
          }

          .table-collapse-item {
            width: 30%;
          }
        }
      }
    }

    .form-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.25px;
      color: $black;
    }

    .form-content {
      padding: 0px;
      gap: 32px;
      width: 774px;
    }

    .formInput-content {
      padding: 0px;
      gap: 32px;
      width: 506px;
    }

    .icon-eye {
      top: 66%;
      transform: translateY(-50%);
      right: 20px;
      cursor: pointer;
    }

    .success {
      border-color: #358b43;
      color: #358b43;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    // .error {
    //   border-color: red;
    //   color: red;
    //   font-weight: 500;
    //   font-size: 16px;
    //   line-height: 24px;
    // }

    .formSwitch {
      padding: 44px;
      gap: 10px;
      width: 774px;
      border: 2px solid #bcbfc4;
      border-radius: 4px;

      &-left {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.25px;
        color: $black;
      }

      &-right {
        .form-check-input {
          width: 48px;
          height: 25px;
          cursor: pointer;
          -webkit-appearance: none !important;
          background-image: url('../assets/images/switch-circle.svg') !important;
          background-color: $switch;
          background-size: 20.5px;
          background-position: 1px center;
        }

        .form-check-input:checked {
          background-color: #f8601b;
          border-color: #f8601b;
          background-position: 24px center !important;
        }
      }
    }

    .form-message {
      gap: 16px;
      width: 774px;
      // height: 48px;
      border-width: 1px 0px;
      background: #fbfcfb;
      padding: 12px 18px;
      border-width: 1px 0px;
      border-style: dashed;
    }
  }

  .inspection-date {
    background-color: #FFF7F2;
    border: 1px solid #FFE7E6;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.modal-confirm {
  height: 208px;
  padding: 30px;
  gap: 10px;

  &-header {
    letter-spacing: 0.25px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }

  &-button {
    margin-top: 20px;
    padding: 0px;
    gap: 24px;

    button {
      padding: 14px 24px;
      gap: 10px;
      border: unset;
      height: 52px;
      width: 245px;
      border-radius: 4px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.25px;
    }

    .btn-cancel {
      background: #fff7f2;
      color: $orange;
    }

    .btn-logout {
      background: $orange;
      color: $white;
    }
  }
}

.inquiryForm {
  .modal-content {
    border-radius: 4px 4px 0px 0px !important;
  }

  &-header {
    border-top: 4px solid #f8601b;
    height: 62px;
    padding: 0 20px;

    &-text {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.25px;
      color: #f8601b;
    }
  }

  &-main {
    background: #f5f5f5;
    padding: 32px 36px;
    gap: 10px;

    &-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
      color: $black;
    }

    &-content {
      padding: 26px 32px;
      gap: 10px;
      background: #ffffff;
      border-radius: 4px;

      .content-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $black;
      }

      .content-main {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $black;
      }
    }
  }
}

.inquiryForm-success {
  .modal-content {
    border-radius: 4px !important;
    padding: 16px 24px 24px;
    gap: 10px;
  }

  .btn-close {
    position: absolute;
    top: 21px;
    right: 18px;
    cursor: pointer;
  }

  .btn-thanks {
    margin-top: 40px;
    position: relative;

    .arrow-up {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);

      border-bottom: 20px solid #f8601b;
    }

    button {
      height: 59px;
      background: #f8601b;
      box-shadow: 0px 6px 0px #d8bfb7;
      border-radius: 30px;
      padding: 12px 32px;
      gap: 10px;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      color: $white;
      border: unset;
    }
  }

  .text-note {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: $black;
  }
}

.resetPassword-modal {
  .modal-content {
    border-radius: 4px !important;
  }

  .resetPassword-modal-content {
    padding: 40px;
    gap: 10px;
  }

  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: $black;
  }

  .btn-bg-white {
    padding: 14px 24px;
    gap: 10px;
    height: 52px;
    background: #fff7f2;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #f8601b;
    border: unset;
    width: calc(50% - 10px);
  }

  .btn-orange {
    width: calc(50% - 10px);
  }
}

.modal-confirm-logout {
  .modal-content {
    border-radius: 4px !important;
    gap: 10px;
  }
}

.profile-setting-item {
  width: 100%;
  height: 162px;
  background: #fff7f2;
  border: 4px solid #ffe6d6;
  border-radius: 4px;
  gap: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: $black;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formSwitch {
  gap: 10px;
  border: 2px solid #bcbfc4;
  border-radius: 4px;
  width: 100%;

  &-left {
    font-weight: 700;
    color: $black;
  }

  &-right {
    .form-check-input {
      width: 48px;
      height: 25px;
      cursor: pointer;
      -webkit-appearance: none !important;
      background-image: url('../assets/images/switch-circle.svg') !important;
      background-color: $switch;
      background-size: 20.5px;
      background-position: 1px center;
    }

    .form-check-input:checked {
      background-color: #f8601b;
      border-color: #f8601b;
      background-position: 24px center !important;
    }
  }
}

.modal-success {
  .modal-dialog {
    max-width: 618px;
    width: 100%;
  }

  .modal-content {
    padding: 28px 38px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      color: $orange;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.25px;
      margin-top: 32px;
      margin-bottom: 0;
    }

    &-sub-title {
      display: block;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.25px;
      margin-top: 16px;
    }

    &-line {
      width: 46px;
      height: 0;
      border: 1px solid #6C757D;
      display: inline-block;
      margin-top: 28px;
    }

    &-txt-email {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #333333;
      margin-top: 12px;
    }
  }

  &__btn {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .contract-icon{
    width: 24px;
    height: 31px;
    position: relative;
    bottom: 6px;
  }
  
  .inquiry-form {
    width: 650px;
  }

  .confirmContract {
    &-left {
      width: 640px;

      hr {
        width: 100%;
      }
    }
  }

  .formSwitch {
    padding: 2em;
    gap: 10px;
    border: 2px solid #bcbfc4;
    border-radius: 4px;
    width: 100%;

    &-left {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.25px;
    }

    &-right {
      .form-check-input {
        width: 48px;
        height: 25px;
        -webkit-appearance: none !important;
        background-image: url('../assets/images/switch-circle.svg') !important;
        background-color: $switch;
        background-size: 20.5px;
        background-position: 1px center;
      }
    }

    &-container {
      width: 50%;
    }
  }

  .profile-setting {
    min-width: 500px;

    &-item {
      margin-bottom: 32px;
    }
  }

  .myPage-container {
    min-height: 100%;

    &-header {
      font-size: 16px;
      line-height: 24px;
      justify-content: center;
      height: 64px;
    }

    &-main {
      min-height: calc(100vh - 326px);
      padding: 24px 12px;
      gap: 24px;

      .title-list {
        font-size: 18px;
        letter-spacing: 0.25px;
      }

      .myPage-list {
        width: 80%;
        gap: 24px;

        &-item {
          width: 40%;
          height: 120px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;

          img {
            width: 45px;
            height: 45px;
          }
        }
      }

      .list-collapse {
        &-item {
          .title-collapse {
            padding: 0;
            font-size: 24px;
            line-height: 34.75px;
            letter-spacing: 0.25px;
          }

          .content-collapse {
            width: 100% !important;
            height: 100% !important;
            border: 4px solid #ffac7d;

            &-top {
              flex-direction: column !important;
              padding: 10px;
              gap: 15px;

              .info-text {
                width: 100% !important;
              }
            }

            &-bottom {
              padding: 34px;
              gap: 10px;

              .link-url {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }

          .data-collapse {
            width: 100%;

            &-item {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.25px;
              padding: 5px 0;
            }
          }
        }
      }

      .form-title {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.25px;
      }

      .form-content {
        gap: 22px;
        width: 100%;
      }

      .formInput-content {
        gap: 22px;
        // width: 100%;
      }

      .confirmContract {
        padding: 16px 12px 20px;
        gap: 10px;

        &-title {
          font-size: 18px;
          line-height: 26px;
        }
        
        &-left {
          // width: 100%;
          margin-top: 20px;

          &-main {
            padding: 16px 12px;
            width: 100%;
            margin-top: 20px;
          }
        }

        &-right {
          // width: 100%;

          &-main {
            padding: 16px 12px;
          }

          &-top {
            margin-top: 25px;
          }
        }
        
      }

      .formSwitch {
        width: 100%;
        padding: 24px 12px;
        gap: 10px;

        &-left {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: $black;
        }
      }

      .form-message {
        width: 100%;
      }
    }
  }

  .inspection-date {
    padding: 12px 32px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .changeID {
    .modal-dialog {
      width: 618px !important;
    }

    .modal-content {
      border-radius: 4px !important;
      padding: 25px 46px;
      gap: 10px;
    }

    &-icon-close {
      width: 14px;
      height: 14px;
      align-self: end;
      cursor: pointer;
    }

    &-text {
      color: $black;
      text-align: center;
      font-size: 14px;

      &.title {
        font-size: 24px;
        font-weight: 700;
      }

      &.content {
        font-weight: 500;
      }
    }

    &-btn {
      margin-top: 30px;
    }

    &-line {
      background: #f8601b;
      height: 1px;
      width: 162px;
      align-self: center;
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 899px) {
  .inquiryForm-success{
    .text-note {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .form-qa-not-login{
    .text-link{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .contract-icon{
    width: 20px;
    height: 26px;
    position: relative;
    bottom: 4px;
  }

  .inquiry-form {
    width: 100%;
  }

  hr {
    width: 100%;
  }

  .confirmContract {
    &-left {
      width: 100%;

      &-main {
        padding: 12px;
      }
    }

    &-right {
      width: 100%;

      &-main {
        width: 100%;
        padding: 12px 0;
        gap: 10px;
      }

    }
  }

  .formSwitch {
    width: 100%;
    padding: 24px 12px;
    gap: 10px;

    &-left {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: $black;
    }

    &-container {
      width: 100%;
    }
  }

  .form-message {
    width: 100%;
  }


  .myPage-container-main {
    .success {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .modal-confirm {
    height: 134px;
    gap: 0px;

    &-header {
      font-size: 14px !important;
      color: #464646;
    }

    &-button {
      button {
        height: 40px;
        font-size: 14px !important;
      }
    }
  }

  .profile-setting {
    width: 100%;

    &-item {
      margin-bottom: 12px;
    }
  }

  .myPage-container {
    min-height: 100%;

    &-header {
      font-size: 16px;
      line-height: 24px;
      justify-content: center;
      // height: 64px;
      flex-direction: column !important;
      padding: 5px 24px;

      .contract {
        justify-content: space-between;
        width: 100%;

        &-name {
          font-size: 12px;
          line-height: 18px;
        }

        &-number {
          font-size: 12px;
        }
      }
    }

    &-main {
      padding: 24px 12px;
      gap: 10px;

      .title-list {
        font-size: 16px;
        letter-spacing: 0.25px;
      }

      .myPage-list {
        width: 100%;
        gap: 10px;

        &-item {
          width: 50%;
          height: 81px;
          font-size: 10px;
          line-height: 18px;

          img {
            width: 32px;
            height: 32px;
          }
        }
      }

      .list-collapse {
        &-item {
          .title-collapse {
            padding: unset;
            font-size: 18px;
            line-height: 26px;
            letter-spacing: 0.25px;
          }

          .collapse-wrapper {
            margin-right: calc(-0.5 * var(--cui-gutter-x));
            margin-left: calc(-0.5 * var(--cui-gutter-x));
          }

          .content-collapse {
            width: 100% !important;
            height: 100% !important;
            border: 2px solid #ffac7d;

            &-top {
              flex-direction: column !important;
              padding: 10px;
              gap: 15px;

              .text-icon {
                font-size: 14px;
              }

              .info-text {
                width: 100% !important;
              }
            }

            &-bottom {
              padding: 14px;
              gap: 12px;

              .text-note {
                font-size: 12px;
                line-height: 20px;
              }

              .link-url {
                align-items: flex-start !important;
                font-size: 14px;
                line-height: 22px;
                word-break: break-all;
                white-space: pre-line;

                .icon {
                  position: relative;
                  top: 4px;
                  left: 0;
                  transform: unset;
                }
              }
            }
          }

          .collapse {
            width: 100%;
          }

          .data-collapse {
            width: 100%;

            &-note {
              font-size: 14px;
              line-height: 20px;
            }

            &-item {
              font-weight: 500;
              font-size: 14px;
              line-height: 22px;
              letter-spacing: 0.25px;
              padding: 5px 0;
            }

            table {
              th {
                padding: 12px;
              }
              tbody td {
                font-size: 12px;
                line-height: 18px;
                padding: 12px;
              }
            }
          }

          .table-collapse {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            &-main {
              font-size: 12px;
              line-height: 16px;
            }

            .table-collapse-item{
              width: 100%;
            }
          }
        }
      }

      .form-title {
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.25px;
      }

      .form-content {
        gap: 22px;
        width: 100%;
      }

      .formInput-content {
        gap: 22px;
        width: 100%;
      }

      .confirmContract {
        padding: 16px 12px 20px;

        &-title {
          font-size: 18px;
          line-height: 26px;
        }

        &-left {
          width: 100%;
          margin-top: 20px;

          &-main {
            padding: 16px 12px;
            margin-top: 20px;
            width: 100%;
          }
        }

        &-right {
          width: 100%;

          &-main {
            padding: 16px 12px;
          }

          &-top {
            margin-top: 25px;
          }
        }
      }

      .title-item-list {
        flex-direction: column !important;
      }

      .title-item {
        width: 100% !important;
      }
    }
  }

  .inquiryForm {
    &-header {
      &-text {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .inspection-date {
    padding: 12px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  .changeID {
    .modal-content {
      border-radius: 4px !important;
      padding: 20px 10px;
      gap: 5px;
    }

    &-icon-close {
      width: 14px;
      height: 14px;
      align-self: end;
      cursor: pointer;
      margin-bottom: 10px;
      margin-left: auto;
    }

    &-input {
      height: 40px;
      font-size: 14px;
    }

    &-text {
      color: $black;
      text-align: center;
      font-size: 12px;

      &.title {
        font-size: 18px;
        font-weight: 700;
      }

      &.content {
        white-space: break-spaces;
        font-weight: 500;
      }
    }

    &-btn {
      margin-top: 25px;
    }

    &-line {
      background: #f8601b;
      height: 1px;
      width: 75px;
      align-self: center;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  .modal-success {
    .modal-dialog {
      width: auto;
    }

    .modal-content {
      padding: 20px 12px;
    }

    &__content {
      &-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }

      &-sub-title {
        font-size: 12px;
        line-height: 16px;
      }

      &-line {
        width: 36px;
      }

      &-txt-email {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .formSwitch {
    &-right {
      .form-check-input {
        width: 48px;
        height: 25px;
        cursor: pointer;
        -webkit-appearance: none !important;
        background-image: url('../assets/images/switch-circle.svg') !important;
        background-color: $switch;
        background-size: 21px;
        background-position: 1px center;
      }

      .form-check-input:checked {
        background-color: #f8601b;
        border-color: #f8601b;
        background-position: 24px center !important;
      }
    }
  }
}
