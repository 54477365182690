.sendMail {
  width: 618px;
  padding: 72px 68px;
  gap: 10px;
  background: $white;
  border-top: 4px solid $orange;
  box-shadow: 0px 4px 18px #fbd7b6;
  border-radius: 4px;

  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.25px;
    color: $black;
  }

  &-message {
    gap: 40px;
    width: 482px;
    height: 48px;
    border-width: 1px 0px;
    background: #fbfcfb;
    padding: 12px 18px;
    border-width: 1px 0px;
    border-style: dashed;
  }

  .success {
    border-color: #358b43;
    color: #358b43;
    font-weight: 500;
    line-height: 24px;
  }

  hr {
    width: 50px;
  }

  &-note {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #333333;
  }
}

.link-terms-of-use {
  padding: 8px 12px;
  gap: 10px;
  background: #FFF7F2;
  border-left: 2px solid #F8601B;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  a {
    text-decoration: underline;
    color: $orange;
    position: relative;
    left: 5px;

    .navigate-text {
      margin-left: 10px;
    }
  }
}

.error {
  gap: 10px;
  width: 100%;
  height: auto;
  border-width: 1px 0px;
  background: #ffe7e6;
  padding: 12px 17px;
  border-width: 1px 0px;
  border-style: dashed;
  color: #ff0f00;
  font-size: 16px;
}

.click-link {
  font-size: 16px;
  line-height: 24px;
  gap: 8px;

  a {
    text-decoration-line: underline !important;
    color: #f8601b !important;
  }
}

.auth-container {
  padding: 72px 68px;
  gap: 10px;
  width: 618px;
  background: $white;
  border-top: 4px solid #f8601b;
  box-shadow: 0px 4px 18px #fbd7b6;
  border-radius: 4px;

  &-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.25px;
    color: $black;
  }

  &-titleMore {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.25px;
    color: $black;

    hr {
      width: 160px;
      border-top: 2px solid #f8601b;
    }
  }

  &-form {
    .icon-eye {
      top: 66%;
      transform: translateY(-50%);
      right: 20px;
      cursor: pointer;
    }

    .remember-password {
      padding: 0px;
      gap: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $black;
      margin-top: 8px;

      .form-check-input {
        -webkit-appearance: none !important;
        cursor: pointer;
      }

      .form-check-input:checked {
        background-color: #f8601b;
        border-color: #f8601b;
      }
    }

    .form-btn-my {
      margin-top: 40px;
      margin-bottom: 32px;
    }

    .text-special {
      color: #6C757D;
      height: 22px;
      position: relative;
      width: 162px;
      margin: 20px 0;

      &-content {
        left: 50%;
        position: absolute;
        background: #fff;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        padding: 0 10px;
        white-space: nowrap;
        width: fit-content;
        z-index: 1;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      &-line {
        background: #6c757d;
        height: 1px;
        width: 100%;
        z-index: 0;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .noti-special {
      padding: 12px;
      gap: 10px;
      width: 100%;
      background: #fff7f2;

      &-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #f8601b;
        gap: 6px;
      }

      &-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.25px;
        color: $black;
      }

      &-time {
        font-size: 12px;
      }
    }

    .redirect-login {
      padding: 12px;
      gap: 10px;
      width: 100%;
      background: #fff7f2;
      border-top: 1px solid #ffac7d;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: #333333;
      text-align: center;

      a {
        text-decoration-line: underline;
        color: #f8601b;
      }
    }

    .term {
      margin-top: 20px;
      padding: 12px 16px;
      gap: 10px;
      background: #fff7f2;
      border-width: 1px 0px;
      border-style: solid;
      border-color: #ffac7d;

      &-top {
        gap: 10px;

        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          text-decoration-line: underline;
          color: #f8601b;
        }
      }

      &-bottom {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: $black;
      }
    }

    .line-auth {
      width: 50px;
      border-top: 2px solid #bcbfc3;
      margin-top: 20px;
    }

    .text-black {
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.25px;
      color: #463529;
    }
  }
}

.container-verify {
  width: 618px;
  background: $white;
  filter: drop-shadow(0px 4px 18px #fbd7b6);

  &-header {
    border-bottom: 1px dashed $orange;
    border-radius: 4px 4px 0 0;
    height: 82px;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $orange;
    padding: 0 24px;
    width: 100%;
  }

  &-table {
    width: 100%;
    padding: 32px 14px;
    gap: 10px;
    background: #fff7f2;

    .main-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.25px;
      color: $black;
      margin-bottom: 30px;
    }

    .main-table {
      padding: 15px 25px;
      gap: 10px;
      width: 90%;
      border: 2px solid #ffac7d;
      border-radius: 4px;

      &-item {
        gap: 20px;
        padding: 10px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $black;
      }

      &-item:nth-child(2) {
        border-color: #bcbfc3;
        border-width: 1px 0px;
        border-style: solid;
        padding: 10px 0;
      }
    }
  }

  &-slide {
    width: 100%;
    padding: 32px;
    gap: 10px;
    background: #ecf0eb;

    .main-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.25px;
      color: $black;
      margin-bottom: 30px;
    }

    .main-table {
      padding: 15px 25px;
      gap: 10px;
      width: 100% !important;
      background: white;
      border: 2px solid #4eae5d;
      border-radius: 4px;

      &-item {
        gap: 20px;
        padding: 10px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.25px;
        color: $black;
      }

      &-item:nth-child(2) {
        border-color: #bcbfc3;
        border-width: 1px 0px;
        border-style: solid;
        padding: 10px 0;
      }
    }
  }

  &-main {
    padding: 32px;
    gap: 10px;

    .main-left {
      width: 100%;
      gap: 32px;

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.25px;
        color: $black;
      }

      &-table {
        padding: 15px 25px;
        gap: 10px;
        width: 100%;
        border: 2px solid #ffac7d;
        border-radius: 4px;
        background: white;

        &-item {
          gap: 20px;
          padding: 10px 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: $black;

          .font-500 {
            font-weight: 500;
          }
        }

        &-item:nth-child(2) {
          border-color: #bcbfc3;
          border-width: 1px 0px;
          border-style: solid;
          padding: 10px 0;
        }
      }
    }

    .main-right {
      width: 90%;
      margin-top: 50px;

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 0.25px;
        color: $black;
      }

      &-description {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.25px;
        color: $black;
      }

      hr {
        width: 160px;
        border-top: 2px solid #f8601b;
      }

      &-form {
        gap: 10px;

        .icon-eye {
          top: 66%;
          transform: translateY(-50%);
          right: 20px;
          cursor: pointer;
        }
      }
    }
  }
}

.modal-register {
  .text-brown {
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $black;
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
  }

  .modal-content {
    border-radius: 4px 4px 0px 0px !important;
  }

  &-main {
    padding: 16px 12px;
    gap: 10px;
    background: #ffffff;
    border-radius: 4px;

    .content {
      img {
        width: 300px;
      }

      &-text {
        padding: 20px 0px;

        .text-orange {
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          letter-spacing: 0.25px;
          color: #f8601b;
          margin: 21px 0;
        }
      }
    }
  }
}

.custom-width {
  width: auto;
}

.modal-slider {
  width: 100%;
  height: 100%;
  padding: 40px;
  align-items: center;

  .slick-dots li button:before {
    font-size: 8px !important;
  }

  .slick-dots li.slick-active button:before {
    color: #000000 !important;
  }

  .custom-arrow:before {
    color: #3ABCEA !important;
    position: absolute;
    transform: scale(1.3);
  }

  .title {
    font-size: 1.1rem;
    color: #3ABCEA;
    font-weight: bold;
  }

  .content {
    color: #FFFFFF;
    background-color: #3ABCEA;
    border-radius: 40%/250%;
    padding: 0 5px;
    line-height: 26px;
  }

  .red {
    color: red;
  }

  .image {
    max-width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }

  .icon-close {
    position: absolute;
    cursor: pointer;
    top: 40px;
    right: 25px;
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 899px) {
  .container-verify-slide {
    .main-table {
      padding: 15px 25px;
      gap: 10px;

      &-item {
        gap: 20px;
        padding: 10px 0;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .error{
    font-size: 14px;
  }

  .sendMail {
    .success {
      line-height: 22px;
    }
  }

  .modal-register {
    .text-brown {
      line-height: 16px;
    }
  }

  .main-left {
    padding: 0 30px;
  }

  .container-verify {
    &-main {
      padding: 32px 2px;
    }
  }

  .custom-width {
    width: 100%;
  }

  .sendMail {
    width: 60%;
    padding: 32px 18px;

    &-title {
      font-size: 18px;
    }

    &-message {
      width: 100%;
    }
  }

  .auth-container {
    width: 80%;
    padding: 36px 18px;
    gap: 10px;

    &-title {
      font-size: 18px;
      line-height: 26px;
    }

    .error {
      width: 100%;
    }
  }

  .container-verify {
    // width: 80%;

    &-header {
      height: 64px;
      font-size: 16px;
      line-height: 20px;
    }

    &-main {
      // padding: 24px 18px;

      .main-left {
        &-title {
          font-size: 18px;
          line-height: 26px;
        }

        &-table {
          // padding: 10px;

          &-item {
            padding: 10px 0;
            font-size: 14px;
            line-height: 20px;
          }

          &-item:nth-child(2) {
            padding: 10px 0;
          }
        }
      }

      .main-right {
        &-title {
          font-size: 18px;
          line-height: 26px;
        }

        &-description {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    &-slide {
      .main-title {
        font-size: 18px;
        line-height: 26px;
      }
    }

    &-table {
      .main-title {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .custom-width {
    width: auto;
  }

  .modal-slider {
    width: 100%;
    height: 100%;
    padding: 40px;
    padding-top: 20px;
    align-items: center;

    .slick-dots li button:before {
      font-size: 8px !important;
    }

    .slick-dots li.slick-active button:before {
      color: #000000 !important;
    }

    .custom-arrow:before {
      color: #3ABCEA !important;
      position: absolute;
      transform: scale(0.8);
    }

    .title {
      font-size: 1.1rem;
      color: #3ABCEA;
      font-weight: bold;
    }

    .content {
      color: #FFFFFF;
      background-color: #3ABCEA;
      border-radius: 40%/250%;
      padding: 0 5px;
      line-height: 26px;
      font-size: 14.4px;
    }

    .content-2 {
      font-size: 14.4px;
      text-align: center;
    }

    .red {
      color: red;
    }

    .image {
      max-width: 80%;
      margin: auto;
      margin-bottom: 5px;
    }

    .icon-close {
      position: absolute;
      cursor: pointer;
      top: 30px;
      right: 15px;
      width: 15px;
      height: 15px;
    }
  }

}

@media screen and (max-width: 739px) {
  .click-link {
    font-size: 14px;
    gap: 2px;
    letter-spacing: 0.25px;
  }

  // .custom-width {
  //   width: 100%;
  // }

  .sendMail {
    width: 100%;
    padding: 32px 18px;

    &-title {
      font-size: 18px;
    }

    &-message {
      width: 100%;
    }
  }

  .auth-container {
    width: 100%;
    padding: 36px 18px;
    gap: 10px;

    &-title {
      font-size: 18px;
      line-height: 26px;
    }

    .error {
      width: 100%;
    }

    &-titleMore {
      font-size: 12px;
      white-space: break-spaces;
    }
  }

  .container-verify {
    &-header {
      height: 64px;
      font-size: 16px;
      line-height: 20px;
    }

    &-main {
      padding: 24px 2px;

      .main-left {
        &-title {
          font-size: 18px;
          line-height: 26px;
        }

        &-table {
          padding: 15px 25px;

          &-item {
            padding: 10px 0;
            font-size: 14px;
            line-height: 20px;
          }

          &-item:nth-child(2) {
            padding: 10px 0;
          }
        }
      }

      .main-right {
        &-title {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }

    &-slide {
      .main-title {
        font-size: 18px;
        line-height: 26px;
      }
    }

    &-table {
      .main-title {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}

.slick-prev:before,
.slick-next:before {
  color: #4eae5d !important;
}

.slick-dots li button:before {
  font-size: 16px !important;
  line-height: 20px;
}

.slick-dots li.slick-active button:before {
  color: #4eae5d !important;
}
