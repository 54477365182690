#RFS-StepperContainer {
    #RFS-StepMain {
        #RFS-StepButton{
            font-family: 'Noto Sans JP';
            pointer-events: none;
            font-size: 16px;
            line-height: 24px;
        }

        #RFS-LabelContainer {
            #RFS-Label {
                font-family: 'Noto Sans JP';
                font-style: normal;
                font-weight: 500;
                align-items: center;
                text-align: center;
                letter-spacing: 0.25px;
                color: #6C757D;
                white-space: pre-wrap;
            }
        }
    }

    #RFS-ConnectorContainer {
        .active {
            border-color: $orange;
        }

        .completed {
            border-color: #faa27b;
        }
    }

    .step-label-active {
        color: $orange;
        font-weight: 700;
    }
}

.StepperContainer-0-2-1 {
    width: 100%;
    padding: 24px 0px !important;
}

@media screen and (min-width: 900px) {
    #RFS-StepMain {
        #RFS-StepButton{
            font-size: 14px;
            line-height: 22px;
        }

        #RFS-LabelContainer {
            #RFS-Label {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }


}

@media screen and (max-width: 899px) {
    #RFS-StepperContainer {
        #RFS-StepMain {
            #RFS-LabelContainer {
                #RFS-Label {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }

        .StepContainer-0-2-2 {
            flex: 1;
            position: relative;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}